.App {
  text-align: center;
  max-width: 500px;
  height: 100vh;
}

#home-page, #upload-page, #rewards-page, #cust-performance-page, #submissions-page, #register-page, #login-page, #verify-page {
  background: radial-gradient(45.93% 55.38% at 50% 63%, #915F97 0%, #724581 58%, #4D2769 100%);
}

#login-page, #verify-page {
background: radial-gradient(45.93% 55.38% at 50% 63%, #915F97 0%, #724581 45%, #4D2769 90%);
}

.gold-text {
  /* background: -webkit-linear-gradient(#eee, #333); */
  background: linear-gradient(90deg, #FFF9AE 0%, #C0A55E 16.2%, #DFCF89 24.97%, #F2E9A3 31.04%, #F9F3AD 35.09%, #F3EBA4 38.47%, #E3D58E 43.19%, #C8B16B 49.26%, #B89B54 53.31%, #F9F3AD 67.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gold-btn {
  background: linear-gradient(90deg, #FFF9AE -0.14%, #C0A55E 23.86%, #DFCF89 36.86%, #F2E9A3 45.86%, #F9F3AD 51.86%, #F3EBA4 56.86%, #E3D58E 63.86%, #C8B16B 72.86%, #B89B54 78.86%, #F9F3AD 99.86%);
  color: rgba(0, 37, 122, 1);
  font-weight: 700;
  border: none;
  height: 48px;
  border-radius: 25px;
}

.gold-border-btn {
  background-color: transparent;
  display: block;
  color: white;
  font-weight: 600;
  border: 2px solid #DFCF89;
  width: 90%;
  margin: 10px auto;
  height: 48px;
  border-radius: 25px;
}

.gold-btn:disabled {
  background-color: #9c9c9c;
  color: white;
}

.mileage-card {
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  margin-top: 40px;
}

.diamond {
  width: 22px;
  height: 22px;
  border: 3.5px solid #00257a;
  rotate: 45deg;
  background-color: #00257a;
}

.diamond.purple {
  border: 3.5px solid #4d2769;
  background-color: #4d2769;
}

.diamond.filled {
background: linear-gradient(271.53deg, #FFF9AE -20.43%, #C0A55E 147.24%, #DFCF89 238.07%, #F2E9A3 300.95%, #F9F3AD 342.87%, #F3EBA4 377.8%, #E3D58E 426.7%, #C8B16B 489.58%, #B89B54 531.5%, #F9F3AD 678.22%);
}

.upload-btn {
  margin-top: 15px;
  cursor: pointer;
  height: 64px;
  width: 64px;
  font-size: 40px;
  box-shadow: 0px 0px 21.100000381469727px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  border: none;
  color: #00257a;
  font-weight: bold;
  border-radius: 50%;
  position: sticky;
  z-index: 100;
}

.upload-file-box {
  position: relative;
  height: 320px;
  width: 85%;
  margin: 15px auto;
  background: linear-gradient(119.67deg, rgba(74, 74, 74, 0.47) -9.89%, rgba(71, 71, 71, 0.21) 117.02%);
  border-radius: 10px;
  border: 0.5px solid rgba(211, 211, 211, 1);
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.12);
}

.upload-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.reward-card {
  border-radius: 16px;
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
}

.copied {
  position: fixed;
  top: 50%;
  right: 20%;
  background-color: rgba(18, 16, 16, 0.85);
  width: 55%;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
}

.box-style-3 {
  padding: 5%;
  width: 100%;
  margin: 10px 0;
  box-shadow: 0px 1px 16px #00000014, inset 1px 1px 4px #ffffff40, inset -2px -2px 2px #00455c14;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.progress-bar {
  width: 100%;
  background-color: #00257a;
  border-radius: 15px;
  height: 20px;
}

.gradient-border {
  display: grid;
  place-items: center;
  background: linear-gradient(90deg, #C8B16B 0%, #625734 100%) ;
  border-radius: 30px;
  padding: 3px;
  box-shadow: 0px 4px 4px 0px rgba(255, 247, 153, 0.21);
  margin-top: 20px;
}

.new-user-btn {
  color: white;
  background-color: #00257a;
  border-radius: 30px;
  font-size: 12px;
  padding: 8px;
  width: 100%;
}

.milestone-container {
  display: inline-block;
  padding: 5px;
  transform: translateY(-15%);
  border-radius: 16px;
}

.gradient-progress {
  height: 20px;
  /* display: inline-block; */
  background: linear-gradient(90deg, #C0A55E 3.83%, #DFCF89 23.5%, #F2E9A3 42.19%, #E3D58E 62.85%, #F9F3AD 82.03%, #B89B54 98.75%)
}

.product-selector {
  border: 1px solid white;
  color: white;
  padding: 10px 15px;
  width: 85%;
  margin: 0 auto;
  border-radius: 20px;
  font-size: 14px;
}

.gold-triangle {
  margin-top: 5px;
  background-image:linear-gradient(90deg, #FFF9AE -969.01%, #C0A55E -671.15%, #DFCF89 -509.81%, #F2E9A3 -398.11%, #F9F3AD -323.64%, #F3EBA4 -261.59%, #E3D58E -174.71%, #C8B16B -63.01%, #B89B54 11.46%, #F9F3AD 272.09%);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  width: 10px;
  height: 10px;
}

.gold-triangle.up {
clip-path: polygon(45% 0, 0 100%, 100% 100%);}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus-visible {
  border: none;
}

/* RETAILER */
.performance-card{
  background: white;
  border-radius: 16px;
  padding: 10px;
}

.leaderboard-container {
  background: white;
  border-radius: 16px;
  padding: 10px 0px;  
}

.top-retailer:first-of-type {
  margin-top: 10px;
}

.top-retailer {
  display: flex;
  width: 90%;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 2px solid rgba(37, 45, 101, 1);
  margin: 0 auto;
  margin-bottom: 10px;
}

.customer-tab {
  background: #797979;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 15px 15px;
  height: 60px;
  width: 50%;
  margin-top: 20px;
}

.customer-tab.active {
  transform: translateY(-15%);
  background: #00257a;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 15px 15px;
  height: 75px;
  width: 50%;
  margin-top: 20px;
}

.small-upload-btn {
  margin: 0 auto;
  height: 24px;
  width: 24px;
  background-color: none;
  color: #00257a;
  border: 2px solid #00257a;
  border-radius: 50%;
  display: grid;
  padding-bottom: 2px;
  font-weight:800;
  place-items: center;
}

.approved-btn {
  margin: 0 auto;
  background-color: #51c927;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: none;
}

.pending-btn {
  margin: 0 auto;
  background-color:#fea30b;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: none;
}

.resubmit-btn {
  margin: 0 auto;
  background-color:#00257a;
  color: white;
  display: grid;
  padding: 4px;
  border-radius: 8px;
  place-items: center;
  border: none;
}

.searchbar {
  height: 8vh;
  background-color: #dcdcdc;
  padding: 0 10px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.search-input {
  margin-top: 4%;
  border-radius: 20px;
  background-color: white;
  border: none;
  padding: 8px;
  width: 35%;
  font-size: 12px;
  height: 32px;
}

 .incentive-card {
  background-color: white;
  border-radius: 16px;
 }

.incentive-gold {
  background: linear-gradient(90deg, #EACB6E 0%, #FDF4A3 32.29%, #FBF3B1 66.14%, #DDC568 99.98%);
  border-radius: 16px;
  width: 90%;
  margin: 0 auto;
}

.arrow-up {
  width: 0; 
  height: 0; 
  margin-left: 5px;
  margin-top: 20px;
  display: block; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #797979;
}

.arrow-down {
  width: 0; 
  height: 0; 
  margin-left: 5px;
  margin-top: 20px;
  display: block; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #797979;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;
  font-size: 12px;
  table-layout: fixed;
}

td,
th {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}

.accumulative-sku-card {
  background: linear-gradient(90deg, #EACB6E 0%, #FDF4A3 32.29%, #FBF3B1 66.14%, #DDC568 99.98%);
  /* padding: 4px; */
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  text-align: center;
}

.blue-gradient-bg {
  background: linear-gradient(90deg, #14437C 0%, #1590C1 32.29%, #1590C1 66.14%, #1A3F78 99.98%);
  border-radius: 10px;
  padding: 8px;
}

.pending-submissions {
  background-color: #fff9ae;
  width: 100%;
  font-weight: 700;
  color: black;
  font-size: 9px;
  margin: 0 auto;
  padding: 0 3px;
  margin-bottom: 10px;
}

/* ANTD STYLING */
.form-input {
  border: 1px solid white;
  border-radius: 25px;
  background-color: transparent;
  color: white !important;
}

.form-input.disabled::placeholder{
color: white !important;
}

.form-input:focus {
  background-color: transparent;
  border: 1px solid white;
  color: black;
}

.form-input::placeholder {
  color: #d0d0d0 !important;
}

.ant-input::placeholder {
  color: #d0d0d0 !important;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background-color: transparent !important;
}

.ant-input-outlined:focus-within {
  background-color: transparent !important;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: white;
}


.ant-table-wrapper {
    /* transform: translateY(-8%); */
}

thead.ant-table-thead > tr > .ant-table-cell {
  background-color: #dcdcdc !important;
  font-size: 11px;
  text-align: center;
  padding: 8px;
  font-weight: 700;
}

div.nu-rebate-table:nth-child(6) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr:nth-child(1) > th:nth-child(2),div.mileage-rebate-table:nth-child(11) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr:nth-child(1) > th:nth-child(2) {
  background: linear-gradient(90deg, #14437C 0%, #1590C1 32.29%, #1590C1 66.14%, #1A3F78 99.98%);
  color: white;
}

div.nu-rebate-table:nth-child(6) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr:nth-child(1) > th:nth-child(3), 
div.nu-rebate-table:nth-child(6) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr:nth-child(1) > th:nth-child(4), 
div.mileage-rebate-table:nth-child(11) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr:nth-child(1) > th:nth-child(3),
div.mileage-rebate-table:nth-child(11) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr:nth-child(1) > th:nth-child(4) {
  background: linear-gradient(90deg, #EACB6E 0%, #FDF4A3 32.29%, #FBF3B1 66.14%, #DDC568 99.98%);
}

div.nu-rebate-table:nth-child(6) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr > th, div.mileage-rebate-table:nth-child(11) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > table:nth-child(1) > thead:nth-child(2) > tr > th {
  font-size: 10px;
}

tbody.ant-table-tbody > tr > .ant-table-cell{
  text-align: center;
}

thead.ant-table-thead > tr > .ant-table-cell:first-child {
  width: 30% !important;
}
tbody.ant-table-tbody > tr > .ant-table-cell:last-child {
  padding-left: 0;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > :first-child{
border-start-start-radius: 0px !important;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > :last-child {
border-start-end-radius: 0px;
}

.ant-btn:hover {
background: linear-gradient(90deg, #FFF9AE -0.14%, #C0A55E 23.86%, #DFCF89 36.86%, #F2E9A3 45.86%, #F9F3AD 51.86%, #F3EBA4 56.86%, #E3D58E 63.86%, #C8B16B 72.86%, #B89B54 78.86%, #F9F3AD 99.86%);
  color: #00257a !important;
}

.ant-switch-inner {
  background-color: #4D2769;
  border: 2px solid #C8B16B;
}

.ant-switch-inner-checked,
.ant-switch-inner-unchecked {
  font-weight: 700;
  color: white !important;
  margin: -3px;
}

.ant-table-wrapper tr.ant-table-expanded-row > td, .ant-table-wrapper tr.ant-table-expanded-row > td, :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper tr.ant-table-expanded-row:hover > td {
background-color: #bcbcbc !important;
color: white;
}

.ant-carousel .slick-dots-bottom {
  bottom: 50px;
}

.slick-dots li.slick-active {
  width: 16px !important;
}

.slick-dots > li > button {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
  background: #d9d9d9;
}

.slick-dots li button {
  border: 2px solid #d9d9d9 !important;
}
.slick-dots li.slick-active button {
  background: #d9d9d9 !important;
}


.customer-performance-table table {
  table-layout: fixed !important;
}

.ant-tooltip {
  max-width: 280px;
}

.ant-tooltip-content {
  max-width: 280px;
}

.ant-select-selector {
  border-radius: 14px !important;
}

.ant-select-selection-placeholder {
    font-size: 12px !important;
}

@media screen and (min-height: 700px) {
  .gold-btn {
    margin-top: 50px;
  }
}